@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';

/*
	Verti by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Basic */

	body {
		background-color: #f0f0f0;
	}

/* Image */

	.image {
		img {
			position: relative;
			-ms-behavior: url('assets/js/ie/PIE.htc');
		}
	}

/* Button */

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	.button,
	button {
		position: relative;
		-ms-behavior: url('assets/js/ie/PIE.htc');
	}

/* Widgets */

	.box,
	.widget.contact ul li a {
		position: relative;
		-ms-behavior: url('assets/js/ie/PIE.htc');
	}

/* Header */

	#logo h1 {
		position: relative;
		-ms-behavior: url('assets/js/ie/PIE.htc');
	}

/* Nav */

	#nav {
		ul {
			li {
				a, span {
					position: relative;
					-ms-behavior: url('assets/js/ie/PIE.htc');
				}
			}
		}
	}

	.dropotron {
		border: solid 1px #eee;
		box-shadow: none !important;
		-ms-behavior: url('assets/js/ie/PIE.htc');

		&.level-0 {
			box-shadow: none !important;

			&:before {
				display: none;
			}
		}
	}